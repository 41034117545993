import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { StorageService } from '@core/services/storage.service';
import { AuthFacade } from '@feature/auth/auth.facade';
import { AuthDialogV2Component } from '../../auth-dialog-v2.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AmplitudeEvent, StorageType } from '@core/enums';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AmplitudeService } from '@core/services/amplitude.service';

@Component({
	selector: 'findqo-interest-survey',
	templateUrl: './interest-survey.component.html',
	styleUrl: './interest-survey.component.scss',
})
export class InterestSurveyComponent extends AbstractAuthComponent implements OnInit {
	constructor(
		private readonly fb: FormBuilder,
		private readonly localStorage: StorageService,
		private readonly profileFacade: AuthFacade,
		private readonly dialogRef: MatDialogRef<AuthDialogV2Component>,
		private readonly amplitudeService: AmplitudeService
	) {
		super();
	}
	readonly interests = [
		{ id: 'isLookingToRent', name: 'n3982' },
		{ id: 'isLookingToBuy', name: 'n3983' },
		{ id: 'isLookingToPostAd', name: 'n3984' },
	];

	readonly form = this.fb.group({
		isLookingToRent: ['0'],
		isLookingToBuy: ['0'],
		isLookingToPostAd: ['0'],
	});

	handleSelection(event: MatSelectionListChange): void {
		const VALUES = event.source._value.reduce((acc, curr) => {
			return { ...acc, [curr['id']]: '1' };
		}, {});

		// Patch default and over right them based on selected options
		this.form.patchValue({
			isLookingToRent: '0',
			isLookingToBuy: '0',
			isLookingToPostAd: '0',
			...VALUES,
		});
	}

	ignoreSurvey(): void {
		this.localStorage.setItem(StorageType.IGNORE_SURVEY, '1');
		this.dialogRef.close();
	}

	submitSurvey(): void {
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.INTEREST_SURVEY_FORM_CONTINUE_CLICKED, { user_email: this.email });
		this.profileFacade.submitSurvey(this.form.value, this.dialogRef);
	}

	ngOnInit(): void {
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.INTEREST_SURVEY_FORM_OPENED, { user_email: this.email });

		// Updated dialog shown count and do not increase more than 3
		const SURVEY_COUNT = +this.localStorage.getItem(StorageType.SURVEY_COUNT);

		if (SURVEY_COUNT < 3) {
			this.localStorage.setItem(StorageType.SURVEY_COUNT, `${SURVEY_COUNT + 1}`);
		}

		// After dialog close, update the last dialog opening time.
		this.dialogRef.beforeClosed().subscribe(() => this.localStorage.setItem(StorageType.SURVEY_SHOWN_AT, new Date().getTime().toString()));
	}
}
