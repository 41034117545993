import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';
import { VALIDATOR } from '@core/constants';
import { AmplitudeService } from '@core/services/amplitude.service';
import { AmplitudeEvent } from '@core/enums';

@Component({
	selector: 'findqo-welcome-v2',
	templateUrl: './welcome-v2.component.html',
	styleUrls: ['./welcome-v2.component.scss'],
})
export class WelcomeV2Component extends AbstractAuthComponent implements OnInit {
	constructor(
		private readonly fb: FormBuilder,
		private readonly authFacade: AuthFacade,
		private readonly amplitudeService: AmplitudeService
	) {
		super();
	}

	form: FormGroup;

	onSubmit(): void {
		const name = this.form.get('name').value;
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.WELCOME_FORM_OPENED, { user_email: this.email, name });

		// Update client name
		this.authFacade.updateClientName(name, this.email, this.changed, this.fromAgentInvitation);
	}

	private createForm(): void {
		this.form = this.fb.group({
			name: ['', [Validators.required, VALIDATOR.pattern.name]],
		});
	}

	ngOnInit(): void {
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.WELCOME_FORM_OPENED, { user_email: this.email });
		this.createForm();
	}
}
