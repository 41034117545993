import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export enum ButtonType {
  FILLED,
  STROKED,
  BASIC,
  ICON,
}

@Component({
  selector: 'findqo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() badge = 0;
  @Input() type = 'button';
  @Input() isDisableRipple = true;
  @Input() isDisabled = false;
  @Input() isLoading = false;
  @Input() btnType = ButtonType.FILLED;
  @Input() label?: string;
  @Input() iconSuffix?: string;
  @Input() iconPrefix?: string;
  @Input() materialIconPrefix?: string;
  @Input() materialIconSuffix?: string;
  @Input() btnClass?: string;
  @Input() btnIconClass?: string;
  // NOTE: If the element has text content, you DON'T need to add aria-label , as it is redundant.
  @Input() ariaLabel?: string;
  @Input('contentTemplate') contentTemplateRef?: TemplateRef<Element> | undefined;
  @Input() isStopPropagation = false;
  @Input() id?: string;
  @Input() color = 'primary';

  @Output() clicked = new EventEmitter<void>();

  iconOverride = ['view-photo', 'chevron', 'map', 'down-arrow'];

  readonly buttonType = ButtonType;

  onClick(event: Event): void {
    if (this.isStopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.isLoading) {
      this.clicked.emit();
    }
  }
}
