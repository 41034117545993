import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmplitudeEvent, AuthType } from '@core/enums';
import { AppEmailValidator } from '@core/validators';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';
import { Observable } from 'rxjs';
import { APP_ROUTE } from '@core/constants';
import { AmplitudeService } from '@core/services/amplitude.service';

@Component({
	selector: 'findqo-login-v2',
	templateUrl: './login-v2.component.html',
	styleUrls: ['./login-v2.component.scss'],
})
export class LoginV2Component extends AbstractAuthComponent implements OnInit {
	constructor(
		private readonly fb: FormBuilder,
		private readonly authFacade: AuthFacade,
		private readonly amplitudeService: AmplitudeService
	) {
		super();
		this.isLoading$ = this.authFacade.isLoading$();
	}

	isLoading$: Observable<boolean>;
	form: FormGroup;

	readonly featureList = ['n2328', 'n2327', 'n2326'];
	readonly appRoute = APP_ROUTE;

	onRequestOtp(): void {
		const EMAIL = this.form.get('email').value;
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.LOGIN_FORM_CONTINUE_CLICKED, { user_email: EMAIL });

		// Requet email OTP
		this.authFacade.requestEmailOtp(EMAIL);

		// Go to OTP screen
		this.changed.emit({
			authType: AuthType.OTP,
			email: EMAIL,
		});
	}

	private createForm(): void {
		this.form = this.fb.group({
			email: ['', [Validators.required, AppEmailValidator.validateEmailPattern()]],
		});
	}

	ngOnInit(): void {
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.LOGIN_FORM_OPENED);
		this.createForm();
	}
}
