import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SKIP_SURVEY_DURATION } from '@core/constants/user-preference.constant';
import { AmplitudeEvent, AuthType, StorageType } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { ClientUser } from '@core/models';
import { IntercomService } from '@core/services/intercom.service';
import { SnackService } from '@core/services/snack.service';
import { StorageService } from '@core/services/storage.service';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';
import { ProfileDisabledDialogComponent } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.component';
import { ButtonType } from '@shared/components/button/button.component';
import { Observable, map, takeWhile, timer } from 'rxjs';
import { AuthDialogV2Component } from '../../auth-dialog-v2.component';
import { AmplitudeService } from '@core/services/amplitude.service';

@Component({
	selector: 'findqo-otp-v2',
	templateUrl: './otp-v2.component.html',
	styleUrls: ['./otp-v2.component.scss'],
})
export class OtpV2Component extends AbstractAuthComponent implements OnInit, OnDestroy {
	constructor(
		private readonly fb: FormBuilder,
		private readonly authFacade: AuthFacade,
		private readonly snackService: SnackService,
		private readonly commonFacade: CommonFacade,
		private readonly dialogRef: MatDialogRef<AuthDialogV2Component>,
		private intercomService: IntercomService,
		private readonly dialog: MatDialog,
		private readonly storageService: StorageService,
		private readonly router: Router,
		private readonly amplitudeService: AmplitudeService
	) {
		super();
		this.isLoading$ = this.authFacade.isLoading$();
	}

	form: FormGroup;
	otpTimeRemaining$: Observable<number>;
	isLoading$: Observable<boolean>;
	showHeading: string;

	readonly startTime = 45;
	readonly authTypeEnum = AuthType;
	readonly buttonTypeEnum = ButtonType;

	async onVerifyEmail(): Promise<void> {
		try {
			this.amplitudeService.handleLoginEvents(AmplitudeEvent.OTP_FORM_VERIFY_CLICKED, { user_email: this.email });

			switch (this.authType) {
				case AuthType.UPDATE_EMAIL_OTP:
					await this.authFacade.verfiyCurrentEmailOtp(this.form.value).then(() => this.handleSuccesVerifyOtp());

					break;
				case AuthType.NEW_EMAIL_OTP:
					await this.authFacade.verfiyNewEmailOtp(this.form.value).then(() => this.handleSuccesVerifyOtp());

					break;
				default:
					// Verify email OTP
					const USER = await this.authFacade.verifyEmailOtp({
						...this.form.value,
						email: this.email,
					});

					this.handleSuccesVerifyOtp(USER);
			}
		} catch {
			// Add error handling here...
		}
	}

	onResendOtp(): void {
		this.startTimer();
		this.authFacade.requestEmailOtp(this.email);
	}

	onChangeEmail(): void {
		if (AuthType.OTP) {
			this.changed.emit({
				authType: this.authType === AuthType.NEW_EMAIL_OTP ? AuthType.NEW_EMAIL : AuthType.LOGIN,
			});
		} else {
			this.changed.emit({
				authType: this.authType === AuthType.NEW_EMAIL_OTP ? AuthType.NEW_EMAIL : AuthType.CHANGE_EMAIL,
				email: this.email,
			});
		}
	}

	private handleSuccesVerifyOtp(user?: ClientUser): void {
		switch (this.authType) {
			case AuthType.UPDATE_EMAIL_OTP:
				this.changed.emit({
					authType: AuthType.NEW_EMAIL,
				});

				break;
			case AuthType.NEW_EMAIL_OTP:
				this.dialogRef.close();
				this.authFacade.updateEmail(this.email);

				this.snackService.open({
					data: { message: this.commonFacade.getTranslations()['n131'] },
					panelClass: ['snackbar-success'],
				});

				break;
			case AuthType.OTP:
				const PREV_TIME = +this.storageService.getItem(StorageType.SURVEY_SHOWN_AT);
				const CURRENT_TIME = new Date().getTime();

				const IS_TIME_EXCEEDED = CURRENT_TIME > PREV_TIME + SKIP_SURVEY_DURATION;
				const SURVEY_COUNT = +this.storageService.getItem(StorageType.SURVEY_COUNT);
				const IGNORE_SURVEY = +this.storageService.getItem(StorageType.IGNORE_SURVEY);

				// Only display the Welcome Screen if the user didn't saved `name` yet
				// TODO: This is not the correct way of checking if  the user is registered or not. The API should create a flag if the user is registered.
				if (!user?.name) {
					// Go to Welcome screen on success
					this.changed.emit({
						authType: AuthType.WELCOME,
						email: this.email,
					});
				} else if (!+user?.isSurveySubmitted && IS_TIME_EXCEEDED && SURVEY_COUNT < 3 && !IGNORE_SURVEY) {
					// Go to interest survey screen
					this.changed.emit({
						authType: AuthType.INTEREST_SURVEY,
						email: this.email,
					});
				} else {
					this.intercomService.addIntercomUser(user);
					this.dialogRef.close();

					const IS_ACCOUNT_DISABLED = +user?.isDisabled;
					// Do not show the dialog from login, if user is going to navigated back on place ad flow
					if (IS_ACCOUNT_DISABLED && !this.isPostAd) {
						this.dialog.open(ProfileDisabledDialogComponent, {
							panelClass: 'post-ad-dialog',
						});

						return;
					}
				}

				break;
			default:
		}
	}

	private createForm(): void {
		this.form = this.fb.group({
			pin: ['', [Validators.required, Validators.minLength(6), Validators.pattern('[0-9]+')]],
		});
	}

	private startTimer(): void {
		this.otpTimeRemaining$ = timer(0, 1000).pipe(
			map((time: number) => (this.startTime - time) * 1000),
			takeWhile((time: number) => time >= 0)
		);
	}

	private setTranslations(): void {
		switch (this.authType) {
			case AuthType.UPDATE_EMAIL_OTP:
				this.showHeading = this.commonFacade.getTranslations()['n109'];

				break;
			case AuthType.NEW_EMAIL_OTP:
				this.showHeading = this.commonFacade.getTranslations()['n98'];

				break;
			case AuthType.OTP:
				this.showHeading = this.commonFacade.getTranslations()['n119'];

				break;
			default:
		}
	}

	showIntercom(): void {
		this.intercomService.showIntercom();
	}

	ngOnInit(): void {
		this.amplitudeService.handleLoginEvents(AmplitudeEvent.OTP_FORM_OPENED, { user_email: this.email });
		this.createForm();
		this.startTimer();
		this.setTranslations();
	}

	ngOnDestroy(): void {
		this.intercomService.shutdownIntercom();
	}
}
