import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';

export enum RouteDataType {
	IS_SECTION_NAV_VISIBLE = 'isSectionNavVisible',
	IS_HEADER_VISIBLE = 'isHeaderVisible',
	IS_FOOTER_VISIBLE = 'isFooterVisible',
	IS_MOBILE_HEADER_VISIBLE = 'isMobileHeaderVisible',
	IS_PLACE_AD_HEADER_VISIBLE = 'isPlaceAdHeaderVisible',
	IS_EMPTY_HEADER_VISIBLE = 'isEmptyHeaderVisible',
	IS_PUBLIC_PAGE = 'isPublicPage',
	SECTION = 'section',
	AISLE = 'aisle',
	STAND = 'stand',
	IS_SELLER_PAGE = 'isSellerPage',
	SHOW_SELLER_INFO = 'showSellerInfo',
	IS_FAVORITE_BTN_ENABLE = 'isFavoritesBtnEnable',
	PAGE_REFERENCE = 'pageReference',
	IS_BOTTOM_NAVBAR_VISIBLE = ' isBottomNavbarVisible',
	IS_AGENT_LANDING_PAGE = 'isAgentLandingPage',
}

export interface RouteData {
	isSectionNavVisible: boolean;
	isHeaderVisible: boolean;
	isFooterVisible: boolean;
	isMobileHeaderVisible: boolean;
	isMobileFooterVisible: boolean;
	isPlaceAdHeaderVisible: boolean;
	isPublicPage: boolean;
	section: string;
	aisle: string;
	stand: string;
	location: string;
	pageReference: string;
	isBottomNavbarVisible: boolean;
	isAgentLandingPage: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class RouteService {
	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router
	) {}

	private readonly _routeData$ = new BehaviorSubject<RouteData>(undefined);
	private readonly _previousUrl = new BehaviorSubject<string>(undefined);

	get routeData$(): Observable<RouteData> {
		return this._routeData$.asObservable();
	}

	getRouteData(): RouteData {
		return this._routeData$.getValue();
	}

	getPreviousUrl(): string {
		return this._previousUrl.getValue();
	}

	getPreviousUrl$(): Observable<string> {
		return this._previousUrl.asObservable();
	}

	setRouteData(routeData: RouteData): void {
		this._routeData$.next(routeData);
	}

	init(): void {
		/**
		 * Get the route data on change of route
		 *
		 * Solved the problem on child components not getting the paras or data.
		 * The parent route will not see a child route data in it's own 'data' or 'params', nor will the child routes see it's parent 'data' directly.
		 *
		 * @link https://stackoverflow.com/questions/43512695/how-to-get-route-data-into-app-component-in-angular-2
		 */
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.route),
				map((route) => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				tap(() => this._previousUrl.next(this.router.url))
			)
			.subscribe((route: ActivatedRoute) => this.setRouteData({ ...route.snapshot.data, ...route.snapshot.params } as RouteData));
	}
}
