import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HeaderFacade } from '@core/components/header/header.facade';
import { AuthType, FeatureVersion } from '@core/enums';
import { StorageType } from '@core/enums/storage.enum';
import { AmplitudeService } from '@core/services/amplitude.service';
import { IntercomService } from '@core/services/intercom.service';
import { StorageService } from '@core/services/storage.service';
import { environment } from '@environments/environment';
import { AuthDialogV2Component } from '@feature/auth/v2/auth-dialog-v2.component';
import * as Sentry from '@sentry/angular';
import { ProfileState } from '../states/profile.state';

export interface AuthDialogData {
	facade?: HeaderFacade;
	onLogin?: () => void;
	authType?: AuthType;
	navigateTo?: string;
	isClosed?: boolean;
	isPostAd?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private readonly router: Router,
		private readonly storageService: StorageService,
		private readonly matDialog: MatDialog,
		private readonly profileState: ProfileState,
		private intercomService: IntercomService,
		private amplitude: AmplitudeService
	) {}

	user(): any {
		try {
			let data = this.storageService.getItem(StorageType.USER);
			if (data) return JSON.parse(data);
		} catch (e) {
			this.storageService.clearStorage();
		}

		return null;
	}

	login(user: any): void {
		this.storageService.setItem(StorageType.USER, JSON.stringify(user));

		if (user?.email && environment.production) {
			// Set userId value as user's email in amplitude dashboard
			this.amplitude.updateUserId(user.email);
			Sentry.setUser({
				id: user.id,
				email: user.email,
			});
		}
	}

	update(user: any): void {
		this.storageService.setItem(StorageType.USER, JSON.stringify(user));
	}

	logout(redirect: boolean = true): void {
		const SURVEY_COUNT = this.storageService.getItem(StorageType.SURVEY_COUNT);
		const SURVEY_SHOWN_AT = this.storageService.getItem(StorageType.SURVEY_SHOWN_AT);
		const IGNORE_SURVEY = this.storageService.getItem(StorageType.IGNORE_SURVEY) ?? '0';

		this.storageService.clear();
		this.profileState.setUser(undefined);
		this.intercomService.shutdownIntercom();
		Sentry.setUser(null);

		// Keep these PREF's after logout, we are storing these based on Device
		this.storageService.setItem(StorageType.SURVEY_COUNT, SURVEY_COUNT);
		this.storageService.setItem(StorageType.SURVEY_SHOWN_AT, SURVEY_SHOWN_AT);
		this.storageService.setItem(StorageType.IGNORE_SURVEY, IGNORE_SURVEY);

		if (redirect) {
			this.router.navigate(['/']);
		}
	}

	/**
	 * TODO: Check how to have pass generic facade or it should be auth facade.
	 *
	 * @param facade
	 */
	openAuthDialog(dialogData?: AuthDialogData): void {
		if (environment.featureVersion === FeatureVersion.V1) {
		}

		switch (environment.featureVersion) {
			case FeatureVersion.V2:
				this.openAuthDialogV2({
					...dialogData,
					authType: AuthType.LOGIN,
					...(!!dialogData?.onLogin && { onLogin: dialogData.onLogin }),
				});

				break;
			default:
		}
	}

	private openAuthDialogV2(dialogData: AuthDialogData): void {
		const { onLogin, navigateTo, facade, ...newDialogData } = { ...dialogData };

		this.matDialog
			.open(AuthDialogV2Component, {
				panelClass: 'auth-dialog',
				disableClose: true,
				...(!!dialogData && { data: newDialogData }),
			})
			.afterClosed()
			.subscribe((afterClosedData: { isClosed?: boolean }) => {
				// If the dialog closed using close button, then emit the callback function of `onLogin`
				if (!afterClosedData?.isClosed && !!onLogin) {
					onLogin();

					return;
				}

				// Navigate the user on the specified route
				if (dialogData?.navigateTo) {
					this.router.navigateByUrl(navigateTo, { onSameUrlNavigation: 'reload' });
				}
			});
	}
}
