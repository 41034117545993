import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AmplitudeEvent } from '@core/enums/amplitude.enum';
import { environment } from '@environments/environment';

interface SendEnquiryAmplitudeEvent {
	user_logged_in?: boolean;
	user_id?: string;
	user_email?: string;
	property_id?: string;
	property_name?: string;
	property_county?: string;
	error?: string;
	enquiry_id?: string;
	environment?: 'local';
}

interface LoginAmplitudeEvent {
	user_email?: string;
	name?: string;
}

@Injectable({
	providedIn: 'root',
})
export class AmplitudeService {
	constructor(@Inject(PLATFORM_ID) private platformId: string) {}

	updateUserId(email: string): void {
		if (isPlatformBrowser(this.platformId)) {
			if (window?.amplitude) {
				window.amplitude.setUserId(email);
			}
		}
	}

	handleSendEnquiryEvents(event: AmplitudeEvent, eventData: SendEnquiryAmplitudeEvent): void {
		if (isPlatformBrowser(this.platformId) && window.amplitude?.track) {
			window.amplitude.track(event, {
				...eventData,
				environment: environment.production ? 'production' : 'dev',
			});
		}
	}

	handleLoginEvents(event: AmplitudeEvent, eventData?: LoginAmplitudeEvent): void {
		if (isPlatformBrowser(this.platformId && window.amplitude?.track)) {
			window.amplitude.track(event, {
				...eventData,
				environment: environment.production ? 'production' : 'dev',
			});
		}
	}
}
